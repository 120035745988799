@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.text-heading {
    font-family: 'Oswald', sans-serif;
  }

  img.section-image {
    transform: scale(0.75);
  }